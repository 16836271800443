<template>
  <div :style="{ backgroundColor: sexStyleType.color02 }">
    <div v-if="false" id="save">
      <headerEl />
      <main id="wrap" class="has-width-limit">
        <div style="margin: 40px; font-size: 0.5rem">
          予約完了画面にリダイレクトしています。
        </div>
      </main>
      <footerEl />
    </div>
    <div id="complete">
      <headerEl />
      <main id="wrap" class="has-width-limit">
        <div id="user-information-complete-vue">
          <div class="frame-block">
            <header class="frame-block-head" :style="{ borderBottomColor: sexStyleType.color01 }">
              <h1 :style="{ color: sexStyleType.color01 }">
                無料カウンセリング<br />
                予約完了いたしました
              </h1>
            </header>
            <div class="frame-block-body">
              <p :style="{ color: sexStyleType.color01 }">
                お申込み、ありがとうございました。<br />
                ご登録いただいたメールアドレスに予約完了メールを<br />
                お送りしますので、ご確認ください。<br />
                ご来院をお待ちしております。
              </p>
            </div>
            <footer class="frame-block-foot">
              <div class="reserve-card block" :style="{ backgroundColor: sexStyleType.color01 }">
                <div class="block-head cols">
                  <div class="col">
                    <p>ご予約のクリニック</p>
                    <h2>{{ completeRes.shopName }}</h2>
                  </div>
                  <!-- <div class="col">
                    <div class="tb-1">
                      <div class="tb-2">
                        <a
                          @click="mapShow = !mapShow"
                          id="mapBtn"
                          class="map-button"
                        >
                          <i class="icon marker"></i>
                          <br />
                          <span>地図で見る</span>
                        </a>
                      </div>
                    </div>
                  </div> -->
                </div>
                <div v-show="mapShow" class="block-body mapArea" style="display: block">
                  <input type="hidden" id="isMapArea" name="isMapArea" value="open" />
                  <iframe width="550" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                    v-if="completeRes.shopMapUrl" :src="completeRes.shopMapUrl"></iframe>
                </div>
                <div class="block-body" style="padding-bottom: 12px">
                  <div class="cols">
                    <div class="col">
                      <p>
                        <small>予約番号 :</small>
                        <br class="mq" />{{ completeRes.reserveCode }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="block-body">
                  <div class="cols">
                    <div class="col">
                      <p>
                        <small>ご予約日 :</small>
                        <br class="mq" />{{
                          completeRes.reserveDate | filterTime
                        }}
                      </p>
                    </div>
                    <div class="col">
                      <p>
                        <small>ご予約時間 :</small>
                        <br class="mq" />{{ completeRes.reserveTime }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="level2-block mb-30" :style="{ backgroundColor: sexStyleType.color02 }">
                <header class="level2-block-head" :style="{ borderBottomColor: sexStyleType.color01 }">
                  <a :href="routerBase() + '/Reserves/login?hash=' + completeRes.hash
                    " class="huge-button orange" @click="login" :style="{ backgroundColor: sexStyleType.color01 }">
                    ペア申し込みはこちら
                  </a>
                </header>
                <div class="level2-block-body no-footer">
                  <h3>
                    ※メールが受信できなかった場合は、ご予約のクリニックまでお電話ください。
                  </h3>
                  <ul class="link-group">
                    <li class="link-list">
                      <p>
                        <span class=" re_el-icon-arrow-down" :style="{ backgroundColor: sexStyleType.color01 }"><i
                            class="el-icon-arrow-down"></i></span>
                        <a @click="careShow = !careShow" id="alertBtn"
                          :style="{ color: sexStyleType.color01 }">ご予約上の注意点について</a>
                      </p>
                      <input type="hidden" id="isAlertArea" name="isAlertArea" value="open" />
                      <div v-show="careShow" class="remarksArea alertArea" style="display: block">
                        <div v-if="jsTag" v-html="jsTag.formDesign.htmlalertreserve"></div>
                        <template v-else>
                          <h4 class="labelttl">
                            （テスト）カウンセリング予約の注意点
                          </h4>
                          ・ご新規の患者さまのみの対応となります。<br />
                          ※会員の患者さまはお手数ですがお電話にてご予約ください。<br /><br />

                          <h4 class="labelttl">メールに関する注意事項</h4>
                          当サイトでは下記の場合、患者さまにメールが届かないことがございますので十分ご注意ください。<br />
                          ・メールアドレスの入力間違い<br />
                          ・患者さまのメールサーバが、当サイトからのメールを「迷惑メール」等に判別してしまう<br /><br />
                          上記を防ぐために、患者さまご自身で、メールアドレス入力内容の確認を行ってください。<br />
                          なお、メール受信ができなかった場合は、直接ご予約のクリニックまでお問い合わせ下さい。<br /><br />

                          <h4 class="labelttl">未成年者に関する注意事項</h4>
                          ・20歳未満の方はご契約時に、親権者さまの同意書（親権者様の自筆での署名・捺印）が必要となりますので親権者さまとご一緒に来院ください。<br />
                          ・親権者さまがご同伴いただけない場合は、当日にご契約いただくことができませんのでカウンセリング時にお渡しする同意書をご記入ご捺印の上、後日ご持参ください。<br />
                          ・医療ローンご利用の場合、5万円以上からとなります。詳しくは、クリニックまでお問い合わせください。<br /><br />

                          <h4 class="labelttl">注意事項</h4>
                          以下に当てはまる症状等がある場合、施術いただけません。あらかじめご了承ください。<br />
                          ・日焼けされている方<br />
                          ・処置対象部に活動性のヘルペス、裂傷または擦過傷がある方<br />
                          ・妊娠されている、またはその疑いのある方、授乳中の方<br />
                          ・悪性腫瘍、HIV感染の疑いがある方<br />
                          ・飲酒、衰弱の激しい方<br />
                          ・治療開始前4週間以内に脱毛剤（ワックス）を使用されている方<br />
                          ・原因不明の皮膚病変がある方<br />
                          ・処置対象部位に活動性の感染がある方<br />
                          ・処置対象部位に影響を及ぼす著しい皮膚症状または炎症性の皮膚症状がある方<br />
                          ・処置対象部位に慢性の感染症、あるいはウイルス、真菌または細菌感染がある方<br />
                          ・皮膚癌の既往歴または処置対象部位に前癌病変がある方<br />
                          また、その他整形やアートメイク、タトゥーをされている方、患者さまの体質・体調によっては施術部位に制限がございます。<br />
                          詳しくは、クリニックまでお問い合わせください。<br /><br />

                          <h4 class="labelttl">
                            ご予約いただく方は下記の内容に同意ください。
                          </h4>
                          ・お子様連れでのご来院はご遠慮いただいております。<br />
                          ・当日はカウンセリングのみ受けていただき、施術はカウンセリング後にご予約いただいております。<br />
                          ・当院からの予約確認・完了メールの送付を許可・承認します。<br /><br />
                        </template>
                      </div>
                    </li>
                    <li class="link-list">
                      <p>
                        <span class=" re_el-icon-arrow-down" :style="{ backgroundColor: sexStyleType.color01 }"><i
                            class="el-icon-arrow-down"></i></span>
                        <a @click="infoShow = !infoShow" id="privacyBtn"
                          :style="{ color: sexStyleType.color01 }">個人情報の取り扱いについて</a>
                      </p>
                      <input type="hidden" id="isPrivacyArea" name="isPrivacyArea" value="open" />
                      <div class="remarksArea privacyArea" style="display: block" v-show="infoShow">
                        <div v-if="jsTag" v-html="jsTag.formDesign.htmlprivacypolicy"></div>
                        <template v-else>
                          （テスト）
                          医療法人社団美実会じぶんクリニック（以下「当クリニック」といいます）が、
                          運営する当サイトにおける個人情報のお取り扱い、ご利用規約について説明します。<br /><br />

                          <h4 class="labelttl">
                            1．個人情報の収集・利用について
                          </h4>
                          当クリニックでは、患者さまに個人情報のご提供をお願いする場合があります。<br /><br />
                          ・サービスをお受けになる際のご予約<br />
                          ・アンケート、サービスに対する患者さまのご要望やご意見の収集<br />
                          ・その他<br /><br />

                          これらは、患者さまへのサービスの提供業務に必要な範囲内で適正・適法な手段によって取得し、サービスの提供と向上、その他の正当な目的のために利用します。また、事前にお伝えした目的の範囲内でのみ利用し、患者さまに同意無くその範囲を超えて利用しません。<br /><br />

                          <h4 class="labelttl">2．個人情報の管理について</h4>
                          当クリニックは、あらかじめご了承をいただいた場合及び法の定めによる場合を除き、第三者にお客様の情報を提供又は開示しません。お預かりした個人情報を漏洩、紛失、改ざん等の事態から防ぐために、適切なセキュリティ対策を講じ厳重に管理します。患者さまの個人情報の取り扱いが適正に行われるように従業者の教育・監督を実施します。<br /><br />

                          <h4 class="labelttl">3．利用制限について</h4>
                          当サイトに掲載される情報、当クリニックでお預かりした情報は、その正確性と最新性の確保に努めます。当サイトに掲載される全ての情報は、当クリニック及びその関連会社が著作権を保有し、各国の著作権法、各種条約及びその他の法律で保護されています。個人の私的使用、その他著作権法によって認められる範囲を超えて、これらの情報を使用（複製、改変、配布）することは、事前に当クリニックから許可を得ない限り禁止します。また、第三者及び当クリニックに不利益や損害を与える行為、公序良俗に反する行為、その恐れがある行為、営利を目的とした行為などはこれを禁止します。<br /><br />

                          <h4 class="labelttl">
                            4．コンテンツ・利用条件の変更について
                          </h4>
                          当クリニックは、予告なしにコンテンツの内容及び利用条件の変更、当サイトに掲載したサービスについての
                          延期・中止・終了を行うことがございますので、予めご了承ください。<br /><br />

                          <h4 class="labelttl">
                            5．個人情報の開示・訂正・利用停止について
                          </h4>
                          患者さまがご自身の情報の内容の開示、訂正、利用停止等を希望された場合はこれに応じます。
                          但し、請求が法令による要件を満たさない場合及び当クリニックの最終のご利用から相当期間を経過した患者さまの情報に関しましては対応できない場合があります。<br /><br />

                          <h4 class="labelttl">6．免責事項について</h4>
                          当サイトの使用及び閲覧は、患者さま自身の自己責任でなされるものであり、当サイトの作成や公開等に関わった当クリニック及び関係者は、当サイトへのアクセス又は使用によって発生したいかなる損害やその修理費用等に関して、一切の責任を負いません。<br /><br />
                        </template>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <a @click="urltoWeb" class="large-button red" target="_blank"
                :style="{ backgroundColor: sexStyleType.color01, borderColor: sexStyleType.color01 }">
                {{ sex == 1 ? 'メンズじぶんクリニックWebサイトへ' : 'じぶんクリニックWebサイトへ' }}
              </a>
            </footer>
          </div>
        </div>
      </main>
      <footerEl />
    </div>
  </div>
</template>

<script>
import headerEl from "@/components/header.vue";
import footerEl from "@/components/footer.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      mapShow: false,
      careShow: false,
      infoShow: false,
      mapUrl:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.3167633188623!2d139.69573031562817!3d35.69382188019142!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188cd6447180d5%3A0x343cbf22f9509a7f!2z44Ki44Oq44K344Ki44Kv44Oq44OL44OD44Kv5paw5a6_6KW_5Y-j6Zmi!5e0!3m2!1sja!2sjp!4v1567474621066!5m2!1sja!2sjp",
    };
  },
  components: { headerEl, footerEl },
  computed: {
    ...mapState([
      "completeInfo",
      "userInfo",
      "completeRes",
      "loginError",
      "jsTag",
      "sex",
      "sexStyleType",
    ]),
  },
  created() {
    // console.log(process.env, 'process.env')
  },
  methods: {
    routerBase() {
      return process.env.VUE_APP_URL;
    },
    login() {
      this.$store.commit("updateLoginError", "");
    },
    urltoWeb() {
      if (this.sex == 1) {
        window.open("https://mens.jibun-clinic.com/");
      } else {
        window.open("https://jibun-clinic.com/");
      }
    },
  },
};
</script>
